import * as striptags from "striptags";
import { BZH_BUCKET_URL } from "./constants"

const objByString = function(o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export const DEFAULT_FONT = "Roboto, Open Sans, Helvetica, Arial, sans-serif";
export const DEFAULT_FONT_SIZE = "16px";
export const PRIMARY_COLOR = "#17477e";
export const PRIMARY_DARK_COLOR = "#0b2c53";

export const newsletterHeaderSection = (title) => {
  return `<mj-section background-color="#fff" padding="0 20px">
            <mj-column>
              <mj-text padding="25px 0px 0px" 
                       font-size="23px"
                       font-weight="700"
                       color="${PRIMARY_COLOR}" 
                       font-family="${DEFAULT_FONT}">
                 ${title}
              </mj-text>
          </mj-column>
        </mj-section>`;
};

export const newsletterElementsSection = (
  elements,
  teaserProp,
  titleProp,
  descriptionProp,
  slugProp,
  urlPrefix,
  urlPrefixSlash = true,
  teaserBZHPrefix = false
) => {

  return elements.map(w =>`
                            <mj-section padding="0 0 30px" background-color="#fff">
                            
                                <mj-column>
                                    <mj-image src="${ teaserBZHPrefix ? BZH_BUCKET_URL + "/" : "" }${ objByString(w, teaserProp) }"></mj-image>
                                </mj-column>
                                
                                <mj-column>
                                    <mj-text padding="5px 10px 0px"
                                             color="${PRIMARY_DARK_COLOR}"
                                             font-size="18px" font-weight="500">
                                        ${w[titleProp]}
                                    </mj-text>
                                
                                    <mj-text padding="5px 10px 7px" > 
                                       ${ striptags(objByString(w, descriptionProp)) }
                                    </mj-text>
                                    
                                    <mj-button href="https://www.reischauer.at/${urlPrefix}${urlPrefixSlash ? "/" : ""}${w[slugProp]}/"
                                                   padding="0 10px"
                                                   align="left" background-color="${PRIMARY_COLOR}" color="#ffffff">
                                            Mehr erfahren
                                    </mj-button>
                                    
                                </mj-column>
                            
                            </mj-section>
                        `).join("\n")


};

export const newsletterElementsSearching = (
  searchings,
  titleProp,
  descriptionProp,
  slugProp,
  urlPrefix,
  urlPrefixSlash = true
) => {

  return searchings.map(w => `<mj-section padding="10px 20px 20px" background-color="#fff">
                                                            
                                <mj-column>
                                    <mj-text font-size="18px" font-weight="500"
                                             color="${PRIMARY_DARK_COLOR}"
                                             padding="0"
                                             font-family="${DEFAULT_FONT}">
                                        ${w[titleProp]}
                                    </mj-text>
                                
                                    <mj-text font-family="${DEFAULT_FONT}"
                                             font-size="${DEFAULT_FONT_SIZE}"
                                             padding="5px 0px 7px" 
                                             >
                                       ${ striptags(objByString(w, descriptionProp)) }
                                    </mj-text>
                                    
                                    <mj-button href="https://www.reischauer.at/${urlPrefix}${urlPrefixSlash ? "/" : ""}${w[slugProp]}/"
                                               padding="0"
                                               align="left" background-color="${PRIMARY_COLOR}" color="#ffffff">
                                        Mehr erfahren
                                    </mj-button>
                                    
                                </mj-column>
                            
                            </mj-section>`).join("\n");
}



export const newsletterHeading = () => {
  return `
                        <mj-section background-color="#0b2c53" padding="0">\t
                      \t<mj-column>
                          <mj-image align="left" 
                                    src="https://reischauer-strapi.s3.eu-central-1.amazonaws.com/newsletter_header_5f09fd204a.png"
                                    width="50px"
                                      
 />
                        </mj-column>
                        
                        <mj-column>
                        \t<mj-text color="#fff"
                                   align="right"
                                   font-size="22px"
                                   font-weight="200"
                                   padding="20px 20px 0"
                                   >
                          \tReischauer Consulting
                          </mj-text>
                          
                          <mj-text color="#fff"
                                   padding="5px 20px 0"
                                   text-decoration="none"
                                   align="right"
                                   font-size="14px"
                                   font-weight="200">
                          \t<a style="color: white; text-decoration: none;" href="https://www.reischauer.at">www.reischauer.at</a>
                          </mj-text>
                          
                        </mj-column>
                        
                      </mj-section>
  `;
};

export const newsletterFooter = () => {
  return `
  <mj-section background-color="#0b2c53" padding="20px 0">\t
               
                        <mj-column>
                        \t<mj-text color="#fff"
                                   align="left"
                                   font-size="22px"
                                   font-weight="200"
                                   padding="20px 20px 0"
                                   >
                          \tReischauer Consulting
                          </mj-text>
                          
                            <mj-text color="#fff"
                                   padding="10px 20px"
                                   align="left"
                                   font-size="14px"
                                   font-weight="200">
                              Durisolstraße 7,
                              A-4600 Wels<br />
                              <br />
                              <p style="margin: 10px 0 0; padding: 0;">
                              <a style="color: white; text-decoration: none;" href="mailto:office@reischauer.at;">office@reischauer.at</a>
                                </p>
                              
                              <p style="margin: 5px 0;">
                              <a style="color: white; text-decoration: none;"
                                 href="tel:+00437242900111"
                                 >+43 7242 9001-11</a>
                                </p>
                          </mj-text>
                          
                          
                          
                          
                          
                          <mj-text color="#fff"
                                   padding="5px 20px 0"
                                   align="left"
                                   text-decoration="none"
                                   font-size="14px"
                                   font-weight="200">
                          \t<a style="color: white; margin: 0; padding: 0; text-decoration: none;" href="https://www.reischauer.at/impressum">Impressum</a>
                          </mj-text>
                          
                          
                          <mj-text color="#fff"
                                   padding="5px 20px 0"
                                   align="left"
                                   font-size="14px"
                                   font-weight="200">
                          \t<a style="color: white; margin: 0; padding: 0; text-decoration: none;" href="https://www.reischauer.at">www.reischauer.at</a>
                          </mj-text>
                          
                        </mj-column>
                        
      </mj-section>
  `;
}
