import React, { useEffect, useState } from "react"
import * as styles from "./newsletter-compose.module.scss";

import * as fileDownload from "js-file-download";

import Layout from "../page-components/layout"
import { graphql } from "gatsby"
import axios from "axios"
import { Formik, Field } from "formik"
import { BZH_GET_URL } from "../helper/constants"
import RcButton from "../components/button/rcButton"
import {
  DEFAULT_FONT,
  newsletterElementsSearching,
  newsletterElementsSection, newsletterFooter,
  newsletterHeaderSection, newsletterHeading, PRIMARY_COLOR, PRIMARY_DARK_COLOR
} from "../helper/newsletter-helper"

const NewsletterComposePage = ( { data } ) => {

  const [ betriebsmarkt, setBetriebsmarkt ] = useState();
  const [ strapiImmos, setStrapiImmos ] = useState();

  useEffect(
    () => {

      const action = async () => {
        try {
          const res = await axios.get(BZH_GET_URL);
          setBetriebsmarkt(res.data);

          const res2 = await axios.get("https://strapi.reischauer.at/immos");
          setStrapiImmos(res2.data);
        } catch(err) {
          console.log(err);
        }
      };

      action();

    },
    []
  );



  return <Layout>

    {/*<Helmet >*/}
    {/*  <meta name="robots" content="noindex" />*/}
    {/*</Helmet>*/}

    <div className={"container bg-white h-100 " + styles.newsletterComposeForm}>
      <div className="row">
        <div className="col-12 py-5">
          <h1>Newsletter Redaktionstool</h1>

          <Formik
            initialValues={
              {
                intro: '',
                end: '',

                eventsSelection: [],
                searchingsSelection: [],
                offersSelection: [],
                wissenSelection: [],
                immosSelection: []
              }
            }
            onSubmit={async (values, { setSubmitting }) => {
              const intro = values.intro;
              const end = values.end;

              const events = [];
              const wissen = [];
              const immos = [];
              const offers = [];
              const searchings = [];

              for (const es of values.eventsSelection) {
                events.push( data.allWpEvent.nodes.find(n => n.id === es) );
              }
              for (const ws of values.wissenSelection) {
                wissen.push( data.allWpWissen.nodes.find(n => n.id === ws));
              }

              for (const is of values.immosSelection) {
                immos.push( strapiImmos.find(n => n.id == is));
              }

              for (const os of values.offersSelection) {
                offers.push( betriebsmarkt.ownOffers.find(n => n.id === os));
              }

              for (const ss of values.searchingsSelection) {
                searchings.push( betriebsmarkt.searchings.find(n => n.id === ss));
              }

              const mjml = `
                <mjml>
                
                    <mj-body background-color="#eee">
                    
                        ${newsletterHeading()}
                    
                        <mj-section background-color="#fff" padding="20px 20px 0">
                          <mj-column>
                            <mj-text align="center"
                                     font-family="${DEFAULT_FONT}"
                                     font-size="32px"
                                     color="${PRIMARY_DARK_COLOR}">
                              Newsletter
                            </mj-text>
                    
                            <mj-text padding="0" font-size="16px" font-family="Roboto, Open Sans, Helvetica, Arial, sans-serif">
                                Sehr geehrte Damen und Herren,
                            </mj-text>
                    
                            ${intro ? `<mj-text padding="10px 0 5px" font-size="16px" font-family="Roboto, Open Sans, Helvetica, Arial, sans-serif">${intro}</mj-text>` : "" }
                          </mj-column>
                        </mj-section>
                        
                        
                        ${wissen.length > 0 && newsletterHeaderSection("Neueste Wissensbeiträge") }
                        
                        ${
                          newsletterElementsSection(wissen, "acf_elements.header.mediaItemUrl", 
                            "title", "acf_elements.description", "slug", "wissen")
                        }
                        
                        ${events.length > 0 &&  newsletterHeaderSection("Neueste Events") }
                        
                        ${
                          newsletterElementsSection(events, "acf_elements.header.mediaItemUrl",
                            "title", "acf_elements.description", "slug", "events")
                        }
                        
                        ${immos.length > 0 && newsletterHeaderSection("Neueste Immobilien") }
                        
                        ${
                        newsletterElementsSection(immos, "Anhang[0].daten.url",
                          "title", "freitexte.objektbeschreibung", "id", "immobilien/Immos_", false)
                        }
                        
                        ${ offers.length > 0 && newsletterHeaderSection("Neueste Betriebsangebote") }
                        
                        ${
                          newsletterElementsSection(offers, "mainpicture.path", "title",
                            "excerpt", "id", "betriebsmarkt?offer=", false, true)
                        }
                        
                        ${ searchings.length > 0 && newsletterHeaderSection("Neueste Suchprofile") }
                        
                        ${
                          newsletterElementsSearching(searchings, "title", "descriptionExcerpt", 
                            "id", "betriebsmarkt?searching=", false)
                        }
                    
                        ${end ? `<mj-section background-color="#fff" padding="40px 20px 15px">
                                   <mj-column>
                                     <mj-text font-size="16px" 
                                              font-family="Roboto, Open Sans, Helvetica, Arial, sans-serif">
                                        ${end}
                                      </mj-text>
                                    </mj-column>
                                 </mj-section>
                                 ` : "" }
                        
                        
                        ${newsletterFooter()}
                        
                    </mj-body>
                
                </mjml>              
              `;

              fileDownload(mjml, "rc-newsletter.mjml");

            }}
          >
            {

              ({
                 values,
                 handleChange,
                 handleBlur,
                 handleSubmit,
               }) => (
                <form className={"rc-form row mb-5 pb-2 col-12"} onSubmit={handleSubmit}>


                    {/*<div className="rc-form-control">*/}
                    {/*  <label htmlFor="intro">Begrüßungs-Formel</label>*/}

                    {/*  <textarea name={"intro"} id={"intro"}*/}
                    {/*            rows={8}*/}
                    {/*            onChange={handleChange}*/}
                    {/*            onBlur={handleBlur} />*/}
                    {/*</div>*/}

                    <div className="rc-form-control" role={"group"}>
                      <label htmlFor="">Wissens-Elemente</label>

                      {
                        data.allWpWissen.nodes.map(
                          w => {
                            return <p>
                              <Field type="checkbox" name="wissenSelection" value={w.id} />
                              { w.title }
                            </p>
                          }
                        )
                      }
                    </div>

                    <div className="rc-form-control" role={"group"}>
                      <label htmlFor="">Events</label>

                      {
                        data.allWpEvent.nodes.map(
                          w => {
                            return <p>
                              <Field type="checkbox" name="eventsSelection" value={w.id} />
                              { w.title }
                            </p>
                          }
                        )
                      }
                    </div>

                    <div className="rc-form-control" role={"group"}>
                      <label htmlFor="">Immobilien</label>

                      {
                        strapiImmos?.map(
                          w => {

                            if (w.title === "EMPTY") return null;

                            return <p>
                              <Field type="checkbox" name="immosSelection" value={w.id.toString()} />
                              { w.title }
                            </p>
                          }
                        )
                      }
                    </div>

                    <div className="rc-form-control" role={"group"}>
                      <label htmlFor="">Betriebsmarkt-Angebote</label>

                      {
                        betriebsmarkt?.ownOffers?.map(
                          w => {
                            return <p>
                              <Field type="checkbox" name="offersSelection" value={w.id} />
                              { w.title }
                            </p>
                          }
                        )
                      }
                    </div>

                    <div className="rc-form-control" role={"group"}>
                      <label htmlFor="">Betriebsmarkt-Suchprofile</label>

                      {
                        betriebsmarkt?.searchings?.map(
                          w => {
                            return <p>
                              <Field type="checkbox" name="searchingsSelection" value={w.id} />
                              { w.title }
                            </p>
                          }
                        )
                      }
                    </div>

                    {/*<div className="rc-form-control">*/}
                    {/*  <label htmlFor="end">Schlussworte</label>*/}

                    {/*  <textarea name={"end"} id={"end"}*/}
                    {/*            rows={8}*/}
                    {/*            onChange={handleChange}*/}
                    {/*            onBlur={handleBlur} />*/}
                    {/*</div>*/}


                    <RcButton>
                      MJML erstellen
                    </RcButton>

                </form>
              )
            }
          </Formik>
        </div>
    </div>
    </div>

  </Layout>

};

export default NewsletterComposePage;

export const query = graphql`
  query NewsletterComposeQuery {
  
  
    allWpWissen {
      nodes {
        id
        slug
        title
        content
        acf_elements {
          description
          header {
            mediaItemUrl
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    
    allWpEvent {
      nodes {
          id
          slug
          title
          content
          acf_elements {
            description
            header {
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
      }
    }   
    
  }
    
    
`
